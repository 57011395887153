import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// components
import { BaseButton, BaseSpacer } from '@/components/partials';
import { ReturnTemplate } from '@/components/features';

// types
import type { ComputedRef } from 'vue';
import type { WopsUserInterface } from '@/classes/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
const router = useRouter();

const isLoading: ComputedRef<boolean> = computed(() => store.getters['returnOrder/getStoreLoading']);
const isComplete: ComputedRef<boolean> = computed(() => store.state.returnOrder.isComplete);

const processReturn = async (): Promise<void> => {
  await store.dispatch('returnOrder/processReturn');
  if (isComplete.value) {
    setTimeout(() => {
      store.dispatch('returnOrder/setIsComplete', false);
    }, 1000);
  }
};

const goToEditReturn = (user: WopsUserInterface): void => {
  router.push({ name: 'Returns', params: { user_id: user.id as string } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ReturnTemplate), null, {
    default: _withCtx((SlotProps) => [
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseButton), {
        ref: "submit",
        color: "success",
        type: "submit",
        class: "process__button",
        onClick: processReturn,
        "is-loading": isLoading.value,
        "is-complete": isComplete.value
      }, {
        complete: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Done, redirecting…")
        ])),
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode(" Submit Return "))
        ]),
        _: 1
      }, 8, ["is-loading", "is-complete"]),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseButton), {
        type: "submit",
        color: "default-alt",
        ref: "editReturn",
        onClick: ($event: any) => (goToEditReturn(SlotProps.user))
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Edit Return ")
        ])),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }))
}
}

})